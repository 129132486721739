<template>
  <CardToolbox>
    <sdPageHeader title="All customers">
      <template #subTitle>
        <sdAutoComplete
          :value="searchKey"
          width="100%"
          placeholder="Search by Email"
          patterns
          @change="onSearchChange"
        />
      </template>
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1">
          <router-link to="/admin/users/add-user/info">+ Add New Customer</router-link>
        </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <CustomerTable />
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import CustomerTable from './overview/CustomerTable.vue';
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox } from '../../styled';

const OrderList = {
  name: 'CustomersList',
  components: { Main, CardToolbox, CustomerTable },
  setup() {
    const { state, dispatch, commit } = useStore();

    const searchKey = computed(() => state.customers.searchKey);
    const onSearchChange = (email) => {
      commit('setSearchCustomerEmail', email);
      dispatch('fetchCustomers');
    };

    const currentStoreView = computed(() => state.auth.activeStoreView);
    watch(currentStoreView, () => {
      dispatch('fetchCustomers');
    });

    onMounted(() => {
      dispatch('fetchCustomers');
    });

    return {
      searchKey,
      onSearchChange,
    };
  },
};

export default OrderList;
</script>
