<template>
  <UserTableStyleWrapper rowClickable>
    <TableWrapper class="table-order table-responsive">
      <a-table
        :dataSource="ordersTableData"
        :columns="ordersTableColumns"
        :loading="isLoading"
        :customRow="customRow"
        :pagination="{
          current,
          defaultPageSize: 12,
          total: totalCount,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }"
        @change="handleTableChange"
      />
    </TableWrapper>
  </UserTableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../../styled';
import { computed } from 'vue';
import { useStore } from 'vuex';
// import { dateFormat, currencyFromCode } from '@/utility/filter';
import { dateFormat } from '@/utility/filter';
import { useRouter } from 'vue-router';

const ordersTableColumns = [
  {
    title: 'ID',
    dataIndex: 'entity_id',
    key: 'id',
  },
  {
    title: 'Total Value',
    dataIndex: 'total_paid',
    key: 'total_paid',
  },
  {
    title: 'Purchase Date',
    dataIndex: 'purchase_date',
    key: 'purchase_date',
  },
  {
    title: 'Payment Provider',
    dataIndex: 'paymentProvider',
    key: 'paymentProvider',
  },
  {
    title: 'Payment status',
    dataIndex: 'payment_status',
    key: 'payment_status',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'left',
  },
];

const paymentMapping = {
  checkoutcompayment: 'Checkout.com',
  paypalpayment: 'PayPal',
  barclayspayment: 'Barclays',
  stripepayment: 'Stripe',
  checkoutcomprevpayment: 'Checkout.com (Prev)',
  cybersourcepayment: 'CyberSource',
  cnpayment: 'Omni',
};

const OrderListTable = {
  name: 'OrderListTable',
  components: { UserTableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const orders = computed(() => state.orders.orders);
    const isLoading = computed(() => state.orders.loading);
    const totalCount = computed(() => state.orders.totalCount);
    const current = computed(() => state.orders.currentPage);
    const pageSize = computed(() => state.orders.pageSize);

    const currency = computed(() => {
      const user = state.auth.user;
      const storeview = state.storeviews.storeview;

      if (user.store_views?.length) {
        if (storeview.id === -1) {
          return user.store_views[0].currency_code;
        }

        const row = user.store_views.find((s) => s.id === storeview.id);
        if (row) {
          return row.currency_code;
        } else {
          return '';
        }
      } else {
        return '';
      }
    });
    console.log(currency, 'currency');
    const handleTableChange = async (pagination) => {
      state.orders.currentPage = pagination.current;
      await dispatch('fetchOrders');
    };

    const ordersTableData = computed(() =>
      orders.value.map((order) => {
        const {
          entity_id,
          base_grand_total,
          payment,
          status,
          grand_total,
          total_invoiced,
          customer_firstname,
          customer_lastname,
          increment_id,
          created_at,
        } = order;

        const labelColors = {
          processing: 'magenta',
          fraud: 'red',
          pending_payment: 'volcano',
          payment_review: 'orange',
          pending: 'gold',
          holded: 'lime',
          STATE_OPEN: 'green',
          complete: 'cyan',
          closed: 'blue',
          canceled: 'geekblue',
        };

        let paymentStatus;
        if (+grand_total === +total_invoiced) {
          paymentStatus = 'Full Invoiced';
        } else if (+grand_total > +total_invoiced) {
          paymentStatus = 'Partially Invoiced';
        } else {
          paymentStatus = 'Not Invoiced';
        }

        const paymentProvider = payment?.method ? paymentMapping[payment.method] : '';

        return {
          key: entity_id,
          entity_id: increment_id,
          // total_paid: base_grand_total ? currencyFromCode(base_currency_code) + base_grand_total : 0,
          total_paid: base_grand_total ? currency.value + ' ' + base_grand_total : 0,
          purchase_date: dateFormat(created_at),
          paymentProvider: paymentProvider,
          payment_status: paymentStatus,
          status: (
            <div class="taglist-wrap">
              <a-tag color={labelColors[status]}>{status === 'processing' ? 'new' : status}</a-tag>
            </div>
          ),
          customer_name: `${customer_firstname} ${customer_lastname}`,
        };
      }),
    );

    const { push } = useRouter();
    const customRow = (record) => ({
      onClick: async () => {
        commit('setHasOrder', true);
        await dispatch('fetchOrder', {
          entityId: record.key,
        });

        push({
          name: 'omni-order-information',
          params: { id: record.key },
        });
      },
    });

    return {
      ordersTableColumns,
      ordersTableData,
      handleTableChange,
      pageSize,
      current,
      orders,
      isLoading,
      totalCount,
      customRow,
      pageSizeOptions: ['12', '48', '120', '250', '500'],
    };
  },
};

export default OrderListTable;
</script>
