<template>
  <sdCards title="Customer Addresses">
    <TableWrapper class="table-responsive mb-30">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :pagination="{
          defaultPageSize: 12,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }"
        @change="handleTableChange"
      />
    </TableWrapper>
  </sdCards>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { TableWrapper } from '@/view/styled';

const tableColumns = [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
  },
  {
    title: 'Street Address',
    dataIndex: 'street',
    key: 'street',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'ZipPostal Code',
    dataIndex: 'postcode',
    key: 'postcode',
  },
  {
    title: 'Phone',
    dataIndex: 'telephone',
    key: 'telephone',
    align: 'left',
  },
];

export default {
  name: 'CustomerAddresses',
  components: {
    TableWrapper,
  },
  setup() {
    const { state } = useStore();

    const tableData = computed(() => {
      return state.customers.customer?.addresses
        ? state.customers.customer.addresses.map((customer) => {
            const { id, firstname, lastname, street, city, country_id, telephone, region, postcode } = customer;

            return {
              key: id,
              first_name: firstname,
              last_name: lastname,
              street: street.join(' '),
              city,
              country: country_id,
              state: region.region,
              postcode,
              telephone,
            };
          })
        : [];
    });
    //TODO: if required default address
    // const billingAddress = computed(() => state.customers.billingAddress);
    // const shippingAddress = computed(() => state.customers.shippingAddress);

    return {
      tableColumns,
      tableData,
    };
  },
};
</script>
