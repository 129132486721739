<template>
  <Suspense>
    <template #default>
      <HorizontalFormStyleWrap>
        <sdCards title="Customer Newsletter">
          <a-form layout="horizontal" labelAlign="left">
            <a-form-item label="Is Subscribed">
              <a-switch :loading="saving" v-model:checked="isSubscribed" @change="onChange" />
            </a-form-item>
          </a-form>
        </sdCards>
      </HorizontalFormStyleWrap>
    </template>
    <template #fallback>
      <sdCards headless>
        <a-skeleton active />
      </sdCards>
    </template>
  </Suspense>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { HorizontalFormStyleWrap } from '@/view/styled';
import { useRoute } from 'vue-router';

export default {
  name: 'CustomerNewsletterPage',
  components: { HorizontalFormStyleWrap },
  setup() {
    const { state, dispatch } = useStore();
    const { params } = useRoute();

    const saving = computed(() => state.customers.saving);
    const isSubscribed = ref(!!state.customers?.customer?.extension_attributes?.is_subscribed);

    const onChange = (checked) => {
      let customer = state.customers.customer;
      customer.extension_attributes.is_subscribed = checked;

      dispatch('updateCustomer', {
        id: params.id,
        newCustomerData: customer,
      });
    };

    return {
      isSubscribed,
      onChange,
      saving,
    };
  },
};
</script>
