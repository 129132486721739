<template>
  <UserTableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="ordersTableData"
        :columns="ordersTableColumns"
        :loading="isLoading"
        @change="handleTableChange"
      />
    </TableWrapper>
  </UserTableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../../styled';
import { computed } from 'vue';
import { useStore } from 'vuex';

const ordersTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First name',
    dataIndex: 'firstname',
    key: 'firstname',
  },
  {
    title: 'Last name',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Created In',
    dataIndex: 'created_in',
    key: 'created_in',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const OrderListTable = {
  name: 'CustomerTable',
  components: { UserTableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const customers = computed(() => state.customers.customers);
    const isLoading = computed(() => state.customers.loading);
    const totalCount = computed(() => state.customers.totalCount);
    const current = computed(() => state.customers.currentPage);
    const pageSize = computed(() => state.customers.pageSize);

    const handleTableChange = async (pagination) => {
      state.orders.currentPage = pagination.current;
      await dispatch('fetchOrders');
    };

    const ordersTableData = computed(() =>
      customers.value.map((order) => {
        const { id, email, firstname, lastname, created_at, created_in } = order;

        const to = {
          name: 'omni-customer-view',
          params: { id },
        };

        return {
          key: id,
          id,
          email,
          firstname,
          lastname,
          created_at,
          created_in,
          action: (
            <div class="table-actions">
              <>
                <router-link to={to}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="edit" size="16" />
                  </sdButton>
                </router-link>
              </>
            </div>
          ),
        };
      }),
    );

    return {
      ordersTableColumns,
      ordersTableData,
      handleTableChange,
      pageSize,
      current,
      isLoading,
      totalCount,
    };
  },
};

export default OrderListTable;
</script>
