<template>
  <sdPageHeader title="Customer details" />
  <Main>
    <CustomerWrapper>
      <div v-if="isLoading" class="spin">
        <a-spin />
      </div>
      <a-row v-else class="justify-content-center" :gutter="25">
        <a-col class="trigger-col" :xxl="5" :xl="7" :lg="9" :xs="24">
          <sdButton
            v-if="responsive <= 991"
            type="link"
            class="mail-sidebar-trigger"
            :style="{ marginTop: 0 }"
            @click="toggleCollapsed"
          >
            <sdFeatherIcons :type="collapsed ? 'align-left' : 'align-right'" />
          </sdButton>

          <div v-if="responsive > 991" class="sidebar-card">
            <sdCards headless>
              <div class="note-sidebar-bottom">
                <NoteNav>
                  <ul>
                    <li v-for="item in navItems" :key="item.id">
                      <router-link :to="path + item.link">
                        <sdFeatherIcons :type="item.icon" size="18" />
                        <span class="nav-text">
                          <span>{{ item.text }}</span>
                        </span>
                      </router-link>
                    </li>
                  </ul>
                </NoteNav>
              </div>
            </sdCards>
          </div>
          <div v-else :class="collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'">
            <sdCards headless>
              <NoteNav>
                <ul>
                  <li v-for="item in navItems" :key="item.id">
                    <router-link :to="path + item.link">
                      <sdFeatherIcons :type="item.icon" size="18" />
                      <span class="nav-text">
                        <span>{{ item.text }}</span>
                      </span>
                    </router-link>
                  </li>
                </ul>
              </NoteNav>
            </sdCards>
          </div>
        </a-col>
        <a-col :xxl="19" :xl="17" :lg="15" :xs="24">
          <router-view></router-view>
        </a-col>
      </a-row>
    </CustomerWrapper>
  </Main>
</template>
<script>
import { CustomerWrapper, NoteNav } from './style';
import { Main } from '../../../../styled';
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'CustomerPage',
  components: { CustomerWrapper, Main, NoteNav },
  setup() {
    const store = useStore();
    const responsive = ref(0);
    const collapsed = ref(false);

    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };
    const collapseSidebar = () => {
      collapsed.value = false;
    };

    const { params } = useRoute();
    const path = `/omni/customers/${params.id}`;

    const isLoading = computed(() => store.state.customers.loading);

    const navItems = [
      {
        id: 'customer_view',
        text: 'Customer View',
        link: '/customer-view',
        icon: 'user',
      },
      // {
      //   id: 'account_information',
      //   text: 'Account Information',
      //   link: '/account-information',
      //   icon: 'info',
      // },
      {
        id: 'addresses',
        text: 'Addresses',
        link: '/addresses',
        icon: 'eye',
      },
      {
        id: 'orders',
        text: 'Orders',
        link: '/orders',
        icon: 'gift',
      },
      // {
      //   id: 'shopping_cart',
      //   text: 'Shopping cart',
      //   link: '/shopping-cart',
      //   icon: 'shopping-cart',
      // },
      {
        id: 'newsletter',
        text: 'Newsletter',
        link: '/newsletter',
        icon: 'share',
      },
    ];

    onMounted(() => {
      function updateSize() {
        const width = window.innerWidth;
        responsive.value = width;
      }
      window.addEventListener('resize', updateSize);
      updateSize();

      if (params.id) {
        store.dispatch('fetchCustomer', {
          id: params.id,
        });
      }
    });

    return {
      collapseSidebar,
      toggleCollapsed,
      collapsed,
      responsive,
      path,
      isLoading,
      navItems,
    };
  },
};
</script>
