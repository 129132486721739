<template>
  <Suspense>
    <template #default>
      <sdCards title="Customer View">
        <a-row justify="center" :gutter="50">
          <a-col :md="12" :xs="24">
            <a-row justify="space-between">
              <a-col>
                <p>E-mail</p>
              </a-col>
              <a-col>
                <p>
                  <b>{{ customer.email }}</b>
                </p>
              </a-col>
            </a-row>
            <a-row justify="space-between">
              <a-col>
                <p>First name</p>
              </a-col>
              <a-col
                ><b>{{ customer.firstname }}</b></a-col
              >
            </a-row>
            <a-row justify="space-between">
              <a-col>
                <p>Group ID</p>
              </a-col>
              <a-col>
                <p>
                  <b>{{ customer.group_id }}</b>
                </p>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="12" :xs="24">
            <a-row justify="space-between">
              <a-col>
                <p>Store ID</p>
              </a-col>
              <a-col>
                <p>
                  <b>{{ customer.store_id }}</b>
                </p>
              </a-col>
            </a-row>
            <a-row justify="space-between">
              <a-col>
                <p>Last name</p>
              </a-col>
              <a-col>
                <p>
                  <b>{{ customer.lastname }}</b>
                </p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </sdCards>
    </template>
    <template #fallback>
      <sdCards headless>
        <a-skeleton active />
      </sdCards>
    </template>
  </Suspense>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  setup() {
    const { state } = useStore();
    const customer = computed(() => state.customers.customer);

    return {
      customer,
    };
  },
};
</script>
