<template>
  <sdCards title="Customer Orders" class="customer-orders">
    <OrderListTable />
  </sdCards>
</template>

<script>
import OrderListTable from '@/view/omni/ordersTable/overview/OrderTable.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    OrderListTable,
  },
  setup() {
    const { commit, dispatch, state } = useStore();

    onMounted(async () => {
      commit('setOrderFilters', {
        increment_id: '',
        created_at: {
          from: null,
          to: null,
        },
        status: '',
        customer_email: state.customers.customer.email,
        location_id: '',
      });
      await dispatch('fetchOrders');
    });
  },
};
</script>

<style lang="scss">
.customer-orders {
  .ant-card-body {
    padding: 0 !important;
  }
}
</style>
