<template>
  <TableWrapper class="table-responsive">
    <a-table
      :dataSource="tableData"
      :columns="tableColumns"
      :loading="isLoading"
      :pagination="{ defaultPageSize: 5 }"
    />
  </TableWrapper>
</template>
<script>
import { TableWrapper } from '@/view/styled';
import { computed } from 'vue';
import { useStore } from 'vuex';

const tableColumns = [
  {
    title: 'Fulfilment',
    dataIndex: 'increment_id',
    key: 'increment_id',
  },
  {
    title: 'Ship Date',
    dataIndex: 'updated_at',
    key: 'updated_at',
  },
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const orderShipmentTable = {
  name: 'OrderShipmentTable',
  components: { TableWrapper },
  setup() {
    const { state } = useStore();
    const shipments = computed(() => state.orders.shipments);
    const order = computed(() => state.orders.order);
    const isLoading = computed(() => state.orders.loading);

    const onEdit = (id) => {
      console.log(id);
    };

    const tableData = computed(
      () =>
        shipments.value
          ? shipments.value.map((shipment) => {
              const { entity_id, increment_id, updated_at, order_id } = shipment;

              const to = {
                name: 'omni-order-shipment-view',
                params: { id: order_id, shipmentId: entity_id },
              };

              return {
                key: entity_id,
                increment_id,
                updated_at,
                order_id: order.value.increment_id,
                action: (
                  <div class="table-actions">
                    <>
                      <router-link to={to}>
                        <sdButton class="btn-icon" type="default" shape="circle">
                          <sdFeatherIcons type="edit" size="16" />
                        </sdButton>
                      </router-link>
                    </>
                  </div>
                ),
              };
            })
          : [],
      // <a-popconfirm title="Sure to delete?" onConfirm={() => onDelete(entity_id)}>
      //   <sdButton class="btn-icon" type="default" shape="circle">
      //     <sdFeatherIcons type="trash" size="16" />
      //   </sdButton>
      // </a-popconfirm>
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      onEdit,
    };
  },
};

export default orderShipmentTable;
</script>
