<template>
  <UserTableStyleWrapper rowClickable>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumn"
        :loading="isLoading"
        :customRow="customRow"
        :pagination="{
          current,
          defaultPageSize: 25,
          total: totalCount,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }"
        @change="handleTableChange"
      />
    </TableWrapper>
  </UserTableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../../styled';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import { currencyFromCode } from '@/utility/filter';

const tableColumn = [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: 'Complete',
    dataIndex: 'complete',
    key: 'complete',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
  },
];
const UserListTable = {
  name: 'UserListTable',
  components: { UserTableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const products = computed(() => state.omni.products);
    const isLoading = computed(() => state.omni.loading);
    const totalCount = computed(() => state.omni.totalCount);
    const current = computed(() => state.omni.currentPage);
    const pageSize = computed(() => state.omni.pageSize);
    const user = computed(() => state.auth.user);
    const productTypeOptions = computed(() => state.omni.attributeOptions['product_group']);
    const activeStoreView = computed(() => state.auth.activeStoreView);

    const handleTableChange = async (pagination) => {
      state.omni.currentPage = pagination.current;
      await dispatch('fetchProducts');
    };

    const tableData = computed(() =>
      products.value
        // .filter(product => product.visibility !== 1 && (product?.is_epc 1== true || (product?.is_epc === true && product?.enhanced_title !== '')))
        .map((product) => {
          console.log(product, 'product');
          const { name, final_price, price, type_id, sku, enhanced_title, image, status, product_group } = product;
          const labelColors = ['geekblue', 'cyan'];
          const productType = productTypeOptions.value?.find((option) => option.value == product_group);
          const calcCompleteness = () => {
            const completenessFields = [
              'name',
              'sku',
              'final_price',
              'national_code',
              'tax_class_id',
              'brand',
              'enhanced_title',
              'description',
              'product_group',
              'symbol',
              'price',
            ];

            const existFieldsCount = completenessFields.filter((field) => !!product[field]);
            return Math.round((existFieldsCount.length / completenessFields.length) * 100);
          };

          return {
            key: sku,
            image: (
              <div class="user-info">
                <figure>
                  <img style={{ width: '40px' }} src={user.value.image_base_url + image} alt="" />
                </figure>
                <figcaption>
                  <sdHeading class="user-name" as="h6">
                    {enhanced_title ?? name}
                  </sdHeading>
                  <span class="user-designation">{type_id}</span>
                </figcaption>
              </div>
            ),
            product_group: productType ? productType.label : '',
            complete: calcCompleteness() + '%',
            price: `${activeStoreView.value.currency_code} ${
              final_price ? Math.round(final_price * 100) / 100 : price ? Math.round(price * 100) / 100 : 0
            }`,
            sku,
            status: (
              <div class="taglist-wrap">
                <a-tag color={labelColors[status]}>{status === 1 ? 'Enabled' : 'Disabled'}</a-tag>
              </div>
            ),
          };
        }),
    );

    const { push } = useRouter();
    const customRow = (record) => ({
      onClick: () => {
        push({
          name: 'omni-product-details',
          params: { sku: record.key },
        });
      },
    });

    return {
      tableColumn,
      tableData,
      handleTableChange,
      pageSize,
      current,
      products,
      isLoading,
      totalCount,
      customRow,
      pageSizeOptions: ['12', '48', '120', '250', '500'],
    };
  },
};

export default UserListTable;
</script>
