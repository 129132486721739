<template>
  <sdCards title="Order Fulfilments">
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <OrderShipments />
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>
</template>
<script>
import { FileCardWrap } from './style';
import OrderShipments from './Table.vue';

export default {
  name: 'OrderShipmentsList',
  components: { FileCardWrap, OrderShipments },
  setup() {},
};
</script>
