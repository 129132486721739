<template>
  <BasicFormWrapper>
    <ProductFilterWrapper>
      <a-form name="multi-form" layout="vertical" class="products-search-filter" @keyup.enter.prevent="applyFilters">
        <!-- <a-row :gutter="30">
          <a-col :lg="24" :sm="24" :xs="24">
            <FilterDiv>
              <span v-if="isShow && filterOptions && Object.keys(filterOptions).length > 0" class="title"
                >Active Filters:</span
              >
              <span v-for="(key, index) in Object.keys(filterOptions)" :key="index">
                <span class="m-20" v-if="filterOptions[key].value">
                  <span>{{ `${key}: ${filterOptions[key].value}` }}</span>
                  <i class="fa fa-times" @click="removeFilter(key)" />
                </span>
              </span>
            </FilterDiv>
          </a-col>
        </a-row> -->
        <!-- <a-row :gutter="30">
          <a-col :lg="6" :sm="12" :xs="24">
            <a-form-item label="Status">
              <a-select v-model:value="formState.status" :style="{ height: '32px !important' }">
                <a-select-option :value="1">Enabled</a-select-option>
                <a-select-option :value="2">Disabled</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="20" class="mb-20">
          <a-col v-for="(row, index) in attributeOptions" :key="`row_${index}`" :md="24" :xs="24">
            <a-menu
              style="width: 100%"
              mode="inline"
              :openKeys="openKeys"
              v-model:selectedKeys="selectedKeys"
              @openChange="onOpenChange"
            >
              <a-sub-menu :key="`row_${index}_sub_menu`">
                <template #title>{{ row.default_frontend_label }}</template>
                <a-menu-item key="1">
                  <a-row :gutter="[16, 16]" v-if="filterOptions[row.attribute_code]">
                    <a-col :md="9" :xs="24">
                      <MultipleFormElement
                        v-model:value="filterOptions[row.attribute_code].value"
                        :options="row.options"
                        :type="row.frontend_input"
                        :disabled="filterOptions[row.attribute_code].blank_filter"
                      />
                    </a-col>
                    <a-col :md="5" :xs="24">
                      <a-checkbox v-model:checked="filterOptions[row.attribute_code].equal_filter">
                        Equal Option
                      </a-checkbox>
                    </a-col>
                    <a-col :md="5" :xs="24">
                      <a-checkbox v-model:checked="filterOptions[row.attribute_code].contain_filter">
                        Contain Option
                      </a-checkbox>
                    </a-col>
                    <!-- <a-col :md="5" :xs="24">
                      <a-checkbox
                        :checked="filterOptions[row.attribute_code].blank_filter"
                        @change="onChange(row.attribute_code)"
                      >
                        Blank Option
                      </a-checkbox>
                    </a-col> -->
                  </a-row>
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-col>
        </a-row>
        <a-row justify="end" :gutter="8">
          <a-col flex>
            <sdButton type="primary" :raised="true" @click="applyFilters" :disable="isSaving">
              {{ isSaving ? 'applying...' : 'Search' }}
            </sdButton>
            <sdButton
              type="primary"
              :style="{ marginLeft: '32px !important' }"
              :raised="true"
              @click="clearFilters"
              :disable="true"
            >
              {{ 'Clear Filters' }}
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </ProductFilterWrapper>
  </BasicFormWrapper>
</template>

<script>
import { BasicFormWrapper } from '../../styled';
import { ProductFilterWrapper } from './style';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import MultipleFormElement from '@/components/multiple-form-element/MultipleFormElement.vue';

export default {
  name: 'ProductFilters',
  components: { BasicFormWrapper, ProductFilterWrapper, MultipleFormElement },
  setup() {
    const { state, commit, dispatch } = useStore();
    const filters = computed(() => state.omni.filters);
    const formState = ref(filters);
    const isSaving = computed(() => state.omni.saving);
    const filterOptions = ref({});
    const attributeOptions = computed(() => state.omni.attributeOptions);
    const isShow = computed(() => state.omni.isShow);

    const onChange = (attributeCode) => {
      const oldValue = filterOptions.value[attributeCode].blank_filter;
      filterOptions.value[attributeCode].blank_filter = !oldValue;
      if (filterOptions.value[attributeCode].blank_filter) {
        filterOptions.value[attributeCode].value = '';
      }
    };
    // console.log(filterData, 'filterData');
    const applyFilters = async () => {
      commit('setProductFilters', filterOptions.value);
      await dispatch('fetchProducts');
      commit('setShow', true);
      commit('setProductFilterVisible', false);
    };

    const clearFilters = async () => {
      Object.keys(filterOptions.value).map((key) => {
        filterOptions.value[key] = {
          value: '',
          equal_filter: false,
          contain_filter: false,
          blank_filter: false,
        };
      });
      applyFilters();
    };

    const removeFilter = (key) => {
      filterOptions.value[key] = {
        value: '',
        equal_filter: false,
        contain_filter: false,
        blank_filter: false,
      };
    };

    const makeFilters = (newValue) => {
      console.log(newValue, 'newValue');
    };

    const loadData = () => {
      filterOptions.value = attributeOptions.value.reduce((result, d) => {
        return {
          ...result,
          [d.attribute_code]: {
            value: '',
            equal_filter: false,
            contain_filter: false,
            blank_filter: false,
          },
        };
      }, {});
    };

    watch(attributeOptions, async () => {
      loadData();
    });

    onMounted(() => {
      commit('setShow', false);
      loadData();
    });

    // onMounted(() => {
    //   commit('setFiltersData', {});
    // });
    // watch(formState, makeFilters, { deep: true });
    console.log(filterOptions, 'filterOptions');
    return {
      formState,
      isSaving,
      applyFilters,
      clearFilters,
      removeFilter,
      isShow,
      makeFilters,
      attributeOptions,
      filterOptions,
      onChange,
    };
  },
};
</script>
