<template>
  <CardToolbox>
    <sdPageHeader title="All products">
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1">
          <router-link to="/omni/product/create">+ Add New Product</router-link>
        </sdButton>
        <sdButton class="btn-add_new" size="default" type="primary" key="1" @click="search"> Search </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <a-drawer
    title="Filter products"
    :width="'50%'"
    :visible="productFilterVisible"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCancel"
  >
    <ProductFilters />
  </a-drawer>
  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <ProductTable />
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import ProductTable from './overview/ProductTable';
import { computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox } from '../../styled';
import ProductFilters from './ProductFilters.vue';

const ProductList = {
  name: 'ProductList',
  components: { Main, CardToolbox, ProductTable, ProductFilters },
  setup() {
    const { state, dispatch, commit } = useStore();

    const isLoading = computed(() => state.omni.loading);
    const searchSku = computed(() => state.omni.searchSku);
    const productFilterVisible = computed(() => state.omni.productFilterVisible);
    const currentStoreView = computed(() => state.auth.activeStoreView);
    const onSearchChange = () => {
      dispatch('fetchProducts');
    };

    const showFilters = ref(false);
    const toggleFilters = () => {
      showFilters.value = !showFilters.value;
    };

    const search = () => {
      commit('setProductFilterVisible', true);
    };

    const handleCancel = () => {
      commit('setProductFilterVisible', false);
    };

    onMounted(() => {
      dispatch('fetchProducts');
    });

    watch(currentStoreView, () => {
      showFilters.value = false;
      commit('clearProductFilters');
      dispatch('fetchProducts');
    });

    return {
      searchSku,
      isLoading,
      onSearchChange,
      showFilters,
      toggleFilters,
      handleCancel,
      productFilterVisible,
      search,
    };
  },
};

export default ProductList;
</script>
